/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import { lazy, Suspense } from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import Navbar from "./components/UI/navbar";
import Loader from "./components/UI/loader";

const HomePage = lazy(() => import("./pages/HomePage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          {/* <Route exact path="/news/:id" component={NewsPage} /> */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
